var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "100%", background: "#fff" } },
    [
      _c(
        "el-container",
        [
          _c(
            "div",
            { staticClass: "left-content" },
            [
              _c("head-layout", {
                attrs: {
                  "head-title": !_vm.readOnly ? "上传列表" : "附件预览",
                  "head-btn-options": _vm.leftBtnOptions,
                },
              }),
              _c(
                "div",
                {
                  staticStyle: {
                    padding: "14px 12px",
                    background: "#ffffff",
                    "text-align": "center",
                  },
                },
                [
                  _c("SingleFIleUpload", {
                    ref: "singleFIleUpload",
                    attrs: {
                      readOnly: _vm.readOnly,
                      accept: "",
                      fileSize: "2000",
                    },
                    on: {
                      fileIds: _vm.handleAvatarSuccess,
                      handleRemove: _vm.handleRemove,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-container",
            { staticStyle: { "padding-bottom": "80px" } },
            [
              _c(
                "el-main",
                { staticStyle: { background: "#ffffff" } },
                [
                  _c("head-layout", {
                    attrs: {
                      "head-btn-options": _vm.headBtnOptions,
                      "head-title": "知识上传",
                    },
                    on: {
                      "head-summary": _vm.headSummary,
                      "head-save": _vm.headSave,
                      "head-submit": _vm.headSubmit,
                      "head-back": _vm.headBack,
                    },
                  }),
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      staticStyle: { padding: "12px" },
                      attrs: {
                        model: _vm.form,
                        rules: _vm.rules,
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "文件名称", prop: "fileName" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              placeholder: "请输入文件名称",
                              disabled: _vm.readOnly,
                              maxlength: "20",
                            },
                            model: {
                              value: _vm.form.fileName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "fileName", $$v)
                              },
                              expression: "form.fileName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "文件标签", prop: "fileTag" } },
                        [
                          _vm._l(_vm.form.fileTag, function (tag) {
                            return _c(
                              "el-tag",
                              {
                                key: tag,
                                attrs: {
                                  closable: !_vm.readOnly,
                                  "disable-transitions": false,
                                },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleClose(tag)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(tag) +
                                    "\n              "
                                ),
                              ]
                            )
                          }),
                          _vm.inputVisible
                            ? _c("el-input", {
                                ref: "saveTagInput",
                                staticClass: "input-new-tag",
                                attrs: { size: "small", maxlength: "50" },
                                on: { blur: _vm.handleInputConfirm },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.handleInputConfirm.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.inputValue,
                                  callback: function ($$v) {
                                    _vm.inputValue = $$v
                                  },
                                  expression: "inputValue",
                                },
                              })
                            : _vm._e(),
                          !_vm.readOnly && !_vm.inputVisible
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "button-new-tag",
                                  attrs: { size: "small" },
                                  on: { click: _vm.showInput },
                                },
                                [_vm._v("新增标签\n              ")]
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "文件封面", prop: "fileCover" } },
                        [
                          _c(
                            "el-upload",
                            {
                              class: {
                                uoloadSty: _vm.showImg,
                                disUoloadSty: _vm.noneUploadImg,
                              },
                              attrs: {
                                action:
                                  "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform",
                                headers: _vm.headers,
                                "list-type": "picture-card",
                                "file-list": _vm.fileList,
                                file: "file",
                                "on-remove": _vm.handleDeleteImgRemove,
                                "on-success": _vm.handleFileCoverSuccess,
                                "on-change": _vm.uploadImgChange,
                                "before-upload": _vm.beforeAvatarUpload,
                                accept: ".jpeg,.jpg,.gif,.png",
                                limit: _vm.limitCountImg,
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "file",
                                  fn: function ({ file }) {
                                    return _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          width: "100%",
                                          height: "100%",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          staticClass:
                                            "el-upload-list__item-thumbnail",
                                          attrs: {
                                            src: _vm.form.fileCover,
                                            alt: "",
                                          },
                                        }),
                                        !_vm.readOnly
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "el-upload-list__item-actions",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "el-upload-list__item-delete",
                                                    on: {
                                                      click:
                                                        _vm.handleDeleteImgRemove,
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-delete",
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  },
                                },
                              ]),
                            },
                            [_c("i", { staticClass: "el-icon-plus" })]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "知识分类", prop: "knowledgeType" } },
                        [
                          _c("avue-input-tree", {
                            staticStyle: { width: "30% !important" },
                            attrs: {
                              props: _vm.inputTreeProps,
                              "default-expand-all": "",
                              placeholder: "请选择",
                              dic: _vm.dic,
                              disabled: _vm.readOnly,
                            },
                            on: { change: _vm.chooseKnowledge },
                            model: {
                              value: _vm.form.knowledgeType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "knowledgeType", $$v)
                              },
                              expression: "form.knowledgeType",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.showClassification
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "文件分类", prop: "fileType" } },
                            [
                              _c("knowledge-classification", {
                                ref: "knowledgeClass",
                                attrs: {
                                  selectedTagId: _vm.form.fileTypeId,
                                  showTagContent: !_vm.readOnly,
                                  view: !_vm.readOnly,
                                  isCheck: false,
                                },
                                on: { "select-tag": _vm.selectTag },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        { attrs: { label: "适用范围", prop: "scopeDept" } },
                        [
                          _c("avue-input-tree", {
                            staticStyle: { width: "30% !important" },
                            attrs: {
                              props: _vm.deptProps,
                              "default-expand-all": "",
                              placeholder: "请选择",
                              dic: _vm.deptDic,
                              disabled: _vm.readOnly,
                            },
                            model: {
                              value: _vm.form.scopeDept,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "scopeDept", $$v)
                              },
                              expression: "form.scopeDept",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.form.scopeApplication == "1"
                        ? _c(
                            "el-form-item",
                            { attrs: { prop: "deptIdList" } },
                            [
                              _c("el-tree", {
                                ref: "deptTree",
                                attrs: {
                                  data: _vm.deptTreeData,
                                  "show-checkbox": "",
                                  "node-key": "id",
                                  "default-expand-all": true,
                                  "default-checked-keys": _vm.defaultChecked,
                                  props: _vm.defaultProps,
                                },
                                on: { "check-change": _vm.handleCheckChange },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        { attrs: { label: "文件摘要", prop: "fileDesc" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              disabled: _vm.readOnly,
                              maxlength: "3000",
                              "show-word-limit": "",
                              rows: 10,
                            },
                            model: {
                              value: _vm.form.fileDesc,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "fileDesc", $$v)
                              },
                              expression: "form.fileDesc",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showFileDialog
        ? _c(
            "common-dialog",
            {
              attrs: {
                width: "70%",
                dialogTitle: "附件预览",
                showConfirmBtn: false,
              },
              on: {
                cancel: function ($event) {
                  _vm.showFileDialog = false
                },
              },
            },
            [
              _c("iframe", {
                ref: "fileIframe",
                staticStyle: { width: "100%", height: "500px" },
                attrs: { src: _vm.fileUrl, frameborder: "0" },
              }),
            ]
          )
        : _vm._e(),
      _c("video", {
        ref: "video",
        staticStyle: { display: "none" },
        attrs: { id: "myVideo", src: _vm.videoUrl, crossOrigin: "anonymous" },
      }),
      _c("canvas", {
        staticStyle: { display: "none" },
        attrs: { id: "myCanvas", crossOrigin: "anonymous" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }